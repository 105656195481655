import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(pacienteId) {
		const params = {
			order: 'asc',
			order_by: 'programmed_date',
			offset: 0,
			limit: 0,
		}
		const { data } = await useJwt.get(
			`${API_ROUTES.assignExamPatient.get}/${pacienteId}`,
			params,
		)

		return data
	},
	save(id, data) {
		return useJwt.put(`${API_ROUTES.assignExamPatient.save}/${id}`, data)
	},
	delete(id, patientId, data) {
		const url = API_ROUTES.assignExamPatient.delete
			.replace(':id', id)
			.replace(':patientId', patientId)
		return useJwt.post(url, data)
	},
	update(id, data) {
		return useJwt.update(`${API_ROUTES.assignExamPatient.update}/${id}`, data)
	},
}
